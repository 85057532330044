/* eslint-disable santa/no-module-state */
let _
let warmupUtilsLib
let layout
let requestRelayout

function init(dependencies, requestRelayoutRef) {
    _ = dependencies.lodash
    warmupUtilsLib = dependencies.warmupUtilsLib
    layout = dependencies.layout
    requestRelayout = requestRelayoutRef

    return {
        defineWixImage
    }
}

const mutationService = {
    measure: imageMeasure => {
        requestRelayout()
        layout.registerOneTimeMeasureCallback(imageMeasure)
    },
    mutate: imagePatcher => {
        layout.registerOneTimePatchCallback(imagePatcher)
    }
}

function defineWixImage(imageLoader, environmentConsts) {
    const imageLayout = {
        measure: layout.specificComponents.imageLayout.measureNodeImage,
        patch: layout.specificComponents.imageLayout.patchNodeImage,
        createDOMPatchers: layout.createDOMPatchers
    }

    const biService = {
        reportBI: _.noop
    }

    warmupUtilsLib.wixCustomElementsRegisrty.defineWixImage({imageLoader, mutationService, imageLayout, biService}, environmentConsts)
}

module.exports = {
    init
}
